import React from "react"

import SectionLayout from "./SectionLayout"

import "./GridSection.css"
import messageImg from "../images/learn-hack-create.jpg"
import lectureAlexaImg from "../images/lecture-alexa.jpg"
import audienceImg from "../images/mozfest-audience.jpg"
import waterAirImg from "../images/water-air-smartspeaker.jpg"
import orangeDrawingImg from "../images/orange-drawing.jpg"
import sketchNotesImg from "../images/sketch-notes.jpg"
import mozfestPresentatorImg from "../images/mozfest-presentator.jpg"
import groupPresentationImg from "../images/group-presentation.jpg"
import hundredRobotImg from "../images/100-robot.jpg"

const GridSection = () => (
  <SectionLayout>
    <div className="grid-section">
      <div className="grid-section__grid">
        <img
          className="grid-section__grid__item"
          src={lectureAlexaImg}
          alt=""
        />
        <img className="grid-section__grid__item" src={sketchNotesImg} alt="" />
        <img className="grid-section__grid__item" src={waterAirImg} alt="" />

        <img
          className="grid-section__grid__item"
          src={orangeDrawingImg}
          alt=""
        />
        <img className="grid-section__grid__item" src={messageImg} alt="" />
        <img
          className="grid-section__grid__item"
          src={groupPresentationImg}
          alt=""
        />

        <img
          className="grid-section__grid__item"
          src={mozfestPresentatorImg}
          alt=""
        />
        <img
          className="grid-section__grid__item"
          src={hundredRobotImg}
          alt=""
        />
        <img className="grid-section__grid__item" src={audienceImg} alt="" />
      </div>
    </div>
  </SectionLayout>
)

export default GridSection
