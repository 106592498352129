import React from "react"
import SectionLayout from "./SectionLayout"
import ContactForm from "./ContactForm"
import "./ContactSection.css"

const ContactSection = () => {
  return (
    <SectionLayout bgColor="#fddb4d" innerMaxWidth="1200px">
      <div className="contact-section" id="contact-section">
        <div className="contact-section__left">
          <h1 className="contact-section__left__header">GET IN TOUCH</h1>
          <p>
            Contact Alexa to book a workshop, talk collaboration opportunities
            or for any other inquiries.
          </p>
        </div>
        <div className="contact-section__right">
          <ContactForm />
        </div>
      </div>
    </SectionLayout>
  )
}

export default ContactSection
