import React from "react"
import "./WorkshopCard.css"
import Button from "./button"

const WorkshopCard = ({ title, workshopType = "", children }) => (
  <div className="workshop-card">
    <div className="workshop-card__top">
      <h2>{title}</h2>
      <p>{children}</p>
      <div className="workshop-card__top__button">
        <Button>Get in touch</Button>
      </div>
    </div>

    <div
      className={
        "workshop-card__bottom " + "workshop-card__bottom--" + workshopType
      }
    ></div>
  </div>
)

export default WorkshopCard
