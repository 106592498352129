import React from "react"
import IntroSection from "../components/IntroSection"
import MozfestSection from "../components/MozfestSection"
import WorkshopSection from "../components/WorkshopSection"
import FAQSection from "../components/FAQSection"
import GridSection from "../components/GridSection"
import ContactSection from "../components/ContactSection"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <IntroSection />
    <MozfestSection />
    <WorkshopSection />
    <FAQSection />
    <GridSection />
    <ContactSection />
  </Layout>
)

export default IndexPage
