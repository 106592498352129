import React from "react"
import SectionLayout from "./SectionLayout"
import WorkshopCard from "./WorkshopCard"

import "./WorkshopSection.css"

const WorkshopsSection = () => (
  <SectionLayout>
    <div className="workshop-section">
      <h1 className="workshop-section__header">OTHER WORKSHOPS</h1>
      <div className="workshop-section__row">
        <div className="workshop-section__row__item">
          <WorkshopCard
            title="MACHINE LEARNING FOR THE CREATIVE & CURIOUS"
            workshopType="workshop1"
          >
            What is machine learning and what do I need to get started? What are
            potential creative uses of this technology?
          </WorkshopCard>
        </div>
        <div className="workshop-section__row__item">
          <WorkshopCard
            title="DEVELOPING SKILLS FOR AMAZON ALEXA"
            workshopType="workshop2"
          >
            How to program a voice assistant and add your own functionalities to
            it? I will show you how to do this for Amazon Alexa.
          </WorkshopCard>
        </div>
        <div className="workshop-section__row__item">
          <WorkshopCard
            title="INTRODUCTION TO ARTIFICIAL INTELLIGENCE"
            workshopType="workshop3"
          >
            What is the current state of AI research, what is AI capable of and
            what not quite yet? How is artificial intelligence different to
            human intelligence? What is a machine learning model? We will go
            through the 10 most important terms and concepts in AI.
          </WorkshopCard>
        </div>
      </div>
    </div>
  </SectionLayout>
)

export default WorkshopsSection
