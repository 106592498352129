import React from "react"
import Button from "./button"
import SectionLayout from "./SectionLayout"
import "./MozfestSection.css"

const MozfestSection = () => (
  <SectionLayout bgColor="#f7f7f7">
    <div className="mozfest-section">
      <div className="mozfest-section__flex">
        <div className="mozfest-section__flex__left"></div>
        <div className="mozfest-section__flex__right">
          <h1>"MY NAME IS ALEXA AND I'M NOT YOUR PERSONAL ASSISTANT"</h1>
          <p className="mozfest-section__lead">
            Workshop held in London, October 2019 during Mozfest, Mozilla's
            annual event.
          </p>
          <p>
            My first name is Alexa and Alexa is also the name of the Amazon
            voice assistant, an artificial intelligence enabled device. I live
            with an Alexa and soon realised that this system is insufficient on
            many levels: 1. The female gendering reinforces old stereotypes of
            women being non-assertive and subservient. 2. The intentional
            personification and pseudo-humanity of it hinders us in establishing
            an AI literacy - a realistic understanding of what today’s AI is
            capable of.
          </p>
          <br />
          <p>In this workshop we're going to </p>
          <p>1. demystify the technical inner workings of voice assistants, </p>
          <p>
            2. critically look at how they are intentionally personified (and
            gendered!) by their designers and ​{" "}
          </p>
          <p>
            3. explore alternative designs & re-imagine these tools together in
            a hands-on exercise!{" "}
          </p>
          <br />
          <p>
            Can we get rid of the reflex of associating (voice) assistants with
            a female gender? Can we approach AI in a non-anthropomorphing way? I
            believe that there is a huge potential for creative exploration
            beyond a human-like persona.
          </p>
          <div className="mozfest-section__bottom">
            <p>Focus of this workshop: ​ </p>
            <p className="mozfest-section__tag">AI LITERACY</p>{" "}
            <p className="mozfest-section__tag">GENDER</p>{" "}
            <p className="mozfest-section__tag">SPECULATIVE DESIGN</p>
          </div>

          <div className="mozfest-section__button">
            <Button link="https://medium.com/@alexasteinbrueck/my-name-is-alexa-and-im-not-your-personal-assistant-workshop-at-mozfest-london-27-10-19-9364fbc54a22">
              Learn more
            </Button>
          </div>
          <div className="mozfest-section__button">
            <Button
              light
              link="https://docs.google.com/presentation/d/1WE6zy5iVFravM6OiEV6lF9A2FzgcZuOzkzVjaQllIRM"
            >
              See slides
            </Button>
          </div>
        </div>
      </div>
    </div>
  </SectionLayout>
)

export default MozfestSection
