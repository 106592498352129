import React from "react"
import { Link } from "gatsby"
import SectionLayout from "./SectionLayout"
import "./IntroSection.css"

const IntroSection = () => (
  <section>
    <SectionLayout>
      <div className="intro-section">
        <div className="intro-section__grid">
          <h1 className="intro-section__header">Hello!</h1>
          <div>
            <p className="intro-section__lead">
              I invent workshops that encourage people to approach cutting-edge
              technology through a creative lens, gain knowledge of its inner
              workings by playing and tinkering with it.
            </p>
            <p className="intro-section__lead">
              I am particularly interested in starting a discussion about
              societal implications of certain technology, especially the
              technologies that fall under the term "A.I."
            </p>
          </div>
        </div>
        <div className="intro-section__grid">
          <h1 className="intro-section__header intro-section__header--ai">
            A.I.
          </h1>
          <div>
            <p className="intro-section__lead">
              Everybody talks about Artificial Intelligence. However, there are
              many myths and misconceptions about AI that need to be replaced
              with more realistic assessments.
            </p>
            <p className="intro-section__lead">
              I studied Artificial Intelligence - before it became hype (Read
              more <Link to="/about">here</Link>) - and can explain the basic
              concepts in a way that even laymen can understand.
            </p>
          </div>
        </div>
        <p className="intro-section__lead">
          Recurring questions in my workshops are:{" "}
          <ul>
            <li>
              What are the core themes of contemporary AI research and
              development?
            </li>
            <li>
              How does contemporary AI differ from the depictions in science
              fiction?
            </li>
            <li>Is AI really trying to imitate human intelligence?</li>
            <li>
              How does AI reproduce injustice of the real world in the digital
              world?
            </li>
          </ul>
        </p>
      </div>
    </SectionLayout>
  </section>
)

export default IntroSection
