import React from "react"
import SectionLayout from "./SectionLayout"
import "./FAQSection.css"

const FAQSection = () => (
  <SectionLayout bgColor="#f7f7f7">
    <div className="faq-section">
      <p className="faq-section__question">
        Q: What's the duration of a workshop?
      </p>
      <p>
        A: The minimum duration is 90 minutes. The more in-depth variants are
        programmed to cover a half-day or full-day schedule.
      </p>
      <p className="faq-section__question">
        Q: What knowledge level is required for the participants?
      </p>
      <p>
        A: Every workshop can be adapted to various levels of knowledge or
        particular interests.
      </p>
      <p className="faq-section__question">
        Q: Do participants have to know programming?
      </p>
      <p>
        A: In the case of the workshop "Developing skills for Alexa Amazon"
        basic programming knowledge in Python or Javascript is a big plus. If
        participants have no prior programming experience I can offer an
        additional "basics of programming" section, which takes about 1-2 hours.
      </p>
      <p className="faq-section__question">
        Q: Where do you offer these workshops? Are you available outside of
        Germany?
      </p>
      <p>
        A: I am based in Leipzig (Germany) and can travel to your location
        within Europe. Please contact me for more details.
      </p>
    </div>
  </SectionLayout>
)

export default FAQSection
