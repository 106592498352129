import React from "react"
import Button from "./button"
import "./ContactForm.css"

const ContactForm = () => {
  return (
    <div className="contact-form">
      <form
        className="boilerform"
        action="contact-success"
        name="Main Contact Form"
        data-netlify="true"
        method="post"
        netlify-honeypot="bot-field"
      >
        <input
          className="contact-form__shoobadoo"
          name="bot-field"
          type="text"
          autoComplete="off"
        />
        <div className="contact-form__row">
          <div>
            <label className="c-label" for="name">
              Name
            </label>
            <input
              type="text"
              required
              name="Name"
              id="name"
              className="c-input-field contact-form__input"
            />
          </div>
          <div>
            <label className="c-label" for="email">
              Email
            </label>
            <input
              type="text"
              required
              name="Email"
              id="email"
              type="email"
              className="c-input-field contact-form__input"
            />
          </div>
        </div>
        <label className="c-label" for="subject">
          Subject
        </label>
        <input
          type="text"
          name="Subject"
          id="subject"
          className="c-input-field contact-form__input"
        />

        <label className="c-label" for="message">
          Type your message here...
        </label>
        <textarea
          name="Message"
          rows="10"
          id="message"
          className="c-input-field c-input-field--multiline contact-form__input"
        ></textarea>

        {/* <Button
          className="contact-form__submit-button"
          onClick="doStuff()"
          style={{ width: "100%" }}
        >
          Submit
      </Button> */}
        <p className="contact-form__gdpr-text">
          Checking this box indicates you understand and accept that the
          information you submit will be stored and viewed according to our{" "}
          <a
            className="contact-form__gdpr-text__link"
            href="https://www.alexasteinbruck.com/privacy.html"
          >
            Privacy Policy.
          </a>
        </p>

        <div className="c-check-field">
          <input
            type="checkbox"
            required
            id="GDPR"
            className="c-check-field__input"
          />
          <label
            for="GDPR"
            className="c-check-field__decor"
            aria-hidden="true"
            role="presentation"
          ></label>
          <label for="GDPR" className="c-check-field__label">
            <p style={{ display: "inline" }}>Yes, I understand and accept</p>
          </label>
        </div>

        <button className="contact-form__submit-button" type="submit">
          Send
        </button>
      </form>
    </div>
  )
}

export default ContactForm
